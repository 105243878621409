import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1657.000000 1657.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1657.000000) scale(0.100000,-0.100000)">

<path d="M2430 7875 l0 -1135 320 0 320 0 0 1135 0 1135 -320 0 -320 0 0
-1135z"/>
<path d="M6840 7750 l0 -1010 245 0 245 0 0 72 0 72 38 -35 c94 -89 214 -129
393 -129 205 0 373 64 499 190 158 158 222 323 222 570 0 153 -18 246 -71 357
-91 190 -231 309 -441 374 -62 20 -97 24 -195 23 -66 0 -145 -6 -175 -13 -63
-16 -162 -63 -203 -97 -16 -13 -33 -24 -38 -24 -5 0 -9 135 -9 330 l0 330
-255 0 -255 0 0 -1010z m942 48 c60 -25 129 -99 154 -163 15 -38 19 -76 19
-160 0 -98 -3 -116 -26 -165 -35 -74 -67 -110 -131 -145 -48 -27 -62 -30 -143
-30 -81 0 -95 3 -143 30 -64 35 -96 71 -131 145 -23 49 -26 67 -26 170 0 103
3 121 26 170 72 153 242 216 401 148z"/>
<path d="M3995 8234 c-224 -21 -376 -80 -489 -189 -102 -98 -145 -240 -117
-388 14 -74 34 -116 82 -171 88 -99 199 -143 481 -187 105 -16 210 -38 234
-49 54 -23 72 -56 53 -96 -22 -45 -83 -63 -215 -63 -166 -1 -328 35 -462 103
-30 15 -56 25 -58 23 -6 -6 -140 -310 -148 -335 -5 -16 6 -25 73 -55 257 -118
697 -143 946 -55 156 55 259 139 317 255 31 64 33 73 32 173 -1 165 -45 252
-168 332 -79 51 -185 83 -375 114 -237 38 -291 57 -311 109 -12 30 18 72 64
91 54 22 206 30 292 15 86 -15 172 -41 244 -75 30 -14 55 -24 56 -23 2 2 142
322 151 343 9 23 -166 83 -317 109 -88 15 -295 26 -365 19z"/>
<path d="M9255 8234 c-224 -21 -376 -80 -489 -189 -102 -98 -145 -240 -117
-388 14 -74 34 -116 82 -171 88 -99 199 -143 481 -187 105 -16 210 -38 234
-49 54 -23 72 -56 53 -96 -22 -45 -83 -63 -215 -63 -166 -1 -328 35 -462 103
-30 15 -56 25 -58 23 -6 -6 -140 -310 -148 -335 -5 -16 6 -25 73 -55 257 -118
697 -143 946 -55 156 55 259 139 317 255 31 64 33 73 32 173 -1 165 -45 252
-168 332 -79 51 -185 83 -375 114 -237 38 -291 57 -311 109 -12 30 18 72 64
91 54 22 206 30 292 15 86 -15 172 -41 244 -75 30 -14 55 -24 56 -23 2 2 142
322 151 343 9 23 -166 83 -317 109 -88 15 -295 26 -365 19z"/>
<path d="M11805 8226 c-110 -21 -212 -69 -289 -137 l-36 -31 0 76 0 76 -245 0
-245 0 0 -735 0 -735 255 0 255 0 0 358 c0 376 7 463 41 543 80 186 362 217
453 49 42 -79 46 -128 46 -550 l0 -400 261 0 261 0 -5 488 c-3 293 -9 507 -16
537 -43 198 -152 346 -305 414 -118 52 -302 72 -431 47z"/>
<path d="M13408 8230 c-79 -13 -188 -52 -256 -92 -86 -50 -211 -180 -254 -263
-58 -111 -81 -210 -81 -345 0 -72 7 -141 17 -185 61 -257 284 -465 550 -514
87 -16 236 -13 319 5 88 20 174 62 229 112 l41 37 -5 -85 c-7 -103 -30 -160
-87 -217 -63 -63 -130 -85 -276 -91 -98 -3 -136 0 -208 17 -99 24 -219 71
-273 108 -20 13 -40 21 -44 16 -14 -14 -180 -339 -180 -351 0 -18 216 -119
305 -142 251 -66 600 -66 797 1 182 61 322 177 397 329 22 47 51 121 63 165
22 78 22 94 26 778 l3 697 -245 0 -246 0 0 -80 c0 -44 -1 -80 -3 -80 -2 0 -26
22 -53 48 -55 53 -131 95 -215 118 -67 18 -245 26 -321 14z m335 -335 c81 -21
127 -47 182 -102 124 -124 151 -320 66 -473 -99 -176 -323 -247 -508 -161
-246 116 -303 440 -110 633 100 100 236 138 370 103z"/>
<path d="M4953 7718 c3 -425 6 -502 21 -558 58 -220 201 -366 411 -421 81 -21
253 -25 337 -8 74 15 185 70 241 119 l47 42 0 -76 0 -76 250 0 250 0 0 735 0
735 -260 0 -260 0 0 -373 c0 -227 -4 -396 -11 -432 -30 -165 -136 -254 -288
-243 -79 6 -120 26 -162 78 -54 67 -59 113 -59 562 l0 408 -261 0 -260 0 4
-492z"/>
<path d="M10361 7320 c-120 -25 -212 -120 -230 -238 -24 -151 37 -273 164
-334 51 -23 73 -28 141 -28 70 0 89 4 142 31 70 35 105 71 140 147 21 48 24
64 20 140 -5 97 -25 147 -80 203 -66 66 -195 100 -297 79z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
